import React, { ReactNode, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@mui/material'
import VipMenu from 'admin/components/regular/VipMenu'
import { useParams } from 'react-router-dom'

const VipPageWrapper = ({ children }: { children: ReactNode }) => {
  
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() =>{
      setLoading(false)
    }, 500)
  }, [])

  return loading
    ? (
      <EmptyState>
        <CircularProgress size={60} />
      </EmptyState>
    )
    : (
      <Wrapper>
        <VipMenu />
        <Content>
          {children}
        </Content>
      </Wrapper>
    )
}

export default VipPageWrapper

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  height: calc(100% - 30px);
  overflow-y: auto;
`

const EmptyState = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
