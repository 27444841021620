import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { IconX } from '@tabler/icons-react'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'

type ModalTypes = {
  children: string | ReactNode
  title?: string | ReactNode
  id?: string
  open: boolean
  onClose: () => void
  showCloseIcon?: boolean
}

const Modal = ({ open, children, title, onClose, id, showCloseIcon = true }: ModalTypes) => {
  if (!open) return null

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const el = document?.getElementById('modal-portal')

  return el && open ? createPortal(
    <Backdrop onMouseDown={onClose}>
      <ModalContainer onMouseDown={handleStopPropagation} id={id}>
        <Header>
          <div />
          {title ? <Title>{title}</Title> : <div />}
          {showCloseIcon ? <CloseIcon onClick={onClose} /> : <div />}
        </Header>
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </Backdrop>,
    el
  ) : null
}

export default Modal

const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px;
  height: 100vh;
  z-index: 1201;
  background-color: rgba(0, 0, 0, 0.65);
`

const ModalContainer = styled.div`
  background: #fff;
  border-radius: 10px;
  max-height: 95vh;
  min-height: 100px;
  max-width: 95vw;
  width: fit-content;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03), 0 40px 40px rgba(0, 0, 0, 0.25),
    0 2px 6px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  color: #424242;
  min-width: 400px;
  padding: 30px;

  ${mobileCss(`
  min-width: 300px;
  padding: 15px;
  `)};

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 20px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  background-color: transparent;
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
`

const CloseIcon = styled(IconX)`
  cursor: pointer;
  color: #ccc;
`

const ModalContent = styled.div`
  height: 100%;
  overflow-y: auto;
`
