import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase'
import { useParams } from 'react-router-dom'
import AdminPageWrapper from 'admin/components/admin/AdminPageWrapper'
import AdminVipForm from 'admin/components/admin/AdminVipForm'

const AdminEditVip
  = () => {
  const [vip, setVip] = useState(undefined)
  const { id } = useParams<{ id: string }>()
  
  const fetchVip = async () => {
    try {
      const response = await supabase.from('vip_links').select().eq('id', id)
      const data = response.data ? response.data[0] : null
      
      if (data) {
        setVip(data)
      }
    } catch (err: any) {
      console.error('Error fetching VIP:', err.message)
    }
  }

  
  useEffect(() => {
    fetchVip()
  }, [])

  return (
    <AdminPageWrapper>
      <AdminVipForm vip={vip} />
    </AdminPageWrapper>
  )
}

export default AdminEditVip
