import React, { useContext, useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import { IconEye, IconPencil } from '@tabler/icons-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { supabase } from 'utils/supabase'
import RemoveVipModal from 'admin/components/RemoveVipModal'
import VipLinkModal from 'admin/components/admin/VipLinkModal'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'

interface Vips {
  vips: VipTableRowTypes[]
  onRemove: () => void
}

const AdminVipsTable = ({ vips, onRemove }: Vips) => {
  
  const navigate = useNavigate()

  useEffect(() => {
    const fetchVip = async () => {
      try {
        const response = await supabase.from('vip_links').select()
        const data = response.data || []
        
      } catch (err) {
        console.error(err)
      }
    }

    fetchVip()
  }, [])

  const sortedVips = vips
    .slice()
    .sort((a, b) => ((a.vipSortingName ?? '') > (b.vipSortingName ?? '') ? 1 : -1))
  
  // @ts-ignore
  return isNotNilOrEmpty(sortedVips) ? (
    <Table>
      <thead>
      <tr>
        <th>Osoba</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {sortedVips.map(vip => (
        <tr key={vip.id}>
          <td>
            <PersonalWrapper>
              {vip?.vipImageUrl ? <img style={{ width: 50 }} src={vip?.vipImageUrl} alt='profile picture' /> : <Placeholder />}
              {vip?.vipFullName || '---'}
            </PersonalWrapper>
          </td>
          <td>
          <Actions>
              <Tooltip title='Unikalny link'>
                <VipLinkModal vip={vip} />
              </Tooltip>
              <Tooltip title='Zobacz podgląd'>
                <IconWrapper onClick={() => {
                  navigate(`/admin/menu/podglad/${vip.id}`);
                }}>
                  <IconEye />
                </IconWrapper>
              </Tooltip>
              <Tooltip title='Edytuj'>
                <IconWrapper onClick={() => {
                  navigate(`/admin/menu/edytuj/${vip.id}`);
                }}>
                  <IconPencil />
                </IconWrapper>
              </Tooltip>
              <Tooltip title='Usuń osobę'>
                <RemoveVipModal id={vip.id} onRemove={onRemove} />
              </Tooltip>
            </Actions>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  ) : (
    <div>Brak aktywnych członków</div>
  )
}

export default AdminVipsTable


const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;

  th {
    text-align: left;
    padding: 16px;
    background-color: #f2f2f2;
  }

  td {
    padding: 8px 16px;
    border-bottom: #ccc;
    border-bottom: 1px solid #f2f2f2;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`

const IconWrapper = styled.div`
  cursor: pointer;
`
const PersonalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Placeholder = styled.div`
  width: 50px;
  height: 50px;
`
