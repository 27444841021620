import React, { useEffect, useRef, useState } from 'react'
import { supabase } from 'utils/supabase';
import toast from 'react-hot-toast'
import { isNotNilOrEmpty } from 'utils/ramda'
import { generateRandomString } from 'utils/common'
import styled from 'styled-components'
import Button from 'components/Button'
import { mobileCss } from 'utils/theme'

interface UploadResponse {
  data: any
  error: Error | null
}

const ImageUploader = ({ onUpload }: { onUpload: (url: string) => void }) => {
  const [selectedFile, setSelectedFile] = useState<string>('')
  const [imageUrl, setImageUrl] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  
  useEffect(() => {
    isNotNilOrEmpty(imageUrl) && onUpload(imageUrl)
  }, [imageUrl])
  
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const fileExtensionRe = /.*\.(.*)/.exec(file.name) || []
      const newFileName = `${generateRandomString()}.${fileExtensionRe[1]}`

      const renamedFile = new File([file], newFileName, { type: file.type })

      // @ts-ignore
      setSelectedFile(renamedFile);
    }
  }

  const uploadImage = async () => {
    try {
      if (!selectedFile) {
        throw new Error('Nie wybrano pliku.')
      }

      const { data, error } : UploadResponse = await supabase
        .storage
        .from('nominees')
        // @ts-ignore
        .upload(selectedFile.name, selectedFile);

      if (error) {
        toast.error('Coś poszło nie tak')
      } else {
        const { data: imageData } = supabase.storage
          .from('nominees')
          .getPublicUrl(data.path)

        if (imageData) {
          setImageUrl(imageData.publicUrl)
        }
      }
    } catch (error) {
      // @ts-ignore
      toast.error('Error uploading image:' + error.message)
    }
  }

  return (
      <UploaderWrapper>
        <Button onClick={handleButtonClick}>Wybierz plik</Button>
        <FileInput accept='image/*' type='file' onChange={handleFileChange} ref={fileInputRef}/>
        <Button onClick={uploadImage}>Wgraj plik</Button>
      </UploaderWrapper>
  )
}

export default ImageUploader;

const UploaderWrapper = styled.div`
  display: flex;
  align-items:  center;
  gap: 10px;
  min-height: 40px;

  ${mobileCss(`
    justify-content: space-between;
  `)};
`

const FileInput = styled.input`
  //padding: 5px 14px;
  display: none;
`
