import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from 'utils/supabase'
import { Button } from '@mui/material'
import styled from 'styled-components'
import AdminPageWrapper from 'admin/components/admin/AdminPageWrapper'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import AdminVipsTable from 'admin/components/admin/AdminVipsTable'
import AddMemberModal from 'admin/components/admin/AddMemberModal'
import { mobileCss } from 'utils/theme'

export interface VipTypes {
  code: string
  id: string
  vipFullName: string
  vipSortingName?: string
}

const AdminVipList = () => {
  
  const [vips, setVips] = useState<VipTableRowTypes[]>([])
  const navigate = useNavigate()
  
  
  const getVips = async () => {
    try {
      const response = await supabase
        .from('vip_links')
        .select('*')
      
      const data = response.data as VipTableRowTypes[]
      
      setVips(data)
      
    } catch(err) {
      console.error(err)
    }
  }
  
  useEffect(() => {
    getVips()
  }, [])
  
  return (
    <AdminPageWrapper>
      <Header>
        <Title>
          Członkowie PRB
        </Title>
        <ButtonsWrapper>
          <AddMemberModal callback={getVips} />
          <Button variant='outlined' onClick={() => navigate('/admin/menu/broszura/wszyscy')}>Zobacz wszystkich</Button>
        </ButtonsWrapper>
      </Header>
      <AdminVipsTable
        vips={vips}
        onRemove={getVips}
      />
    </AdminPageWrapper>
  )
}

export default AdminVipList

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  ${mobileCss(`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  `)};
`

const Title = styled.div`
  font-size: 24px;

  ${mobileCss(`
   width: auto;
   font-size: 18px;
   margin-bottom: 10px;
  `)};
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  ${mobileCss(`
  display: flex;
  flex-direction: column,
  `)};
`
