import React, { useEffect } from 'react'
import { Tooltip } from '@mui/material'
import { IconEye } from '@tabler/icons-react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'

interface Vips {
  vips: VipTableRowTypes[]
  onRemove: () => void
}

const VipsTable = ({ vips, onRemove }: Vips) => {
  
  const navigate = useNavigate()
  const { code } = useParams()

  const sortedVips = vips
    .slice()
    .sort((a, b) => ((a.vipSortingName ?? '') > (b.vipSortingName ?? '') ? 1 : -1))
  
  return isNotNilOrEmpty(sortedVips) ? (
    <Table>
      <thead>
      <tr>
        <th>Osoba</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {sortedVips.map(vip => (
        <tr key={vip.id}>
          <td>
            <PersonalWrapper>
              {vip?.vipImageUrl ? <img style={{ width: 50 }} src={vip?.vipImageUrl} alt='profile picture' /> : <Placeholder />}
              {vip?.vipFullName || '---'}
            </PersonalWrapper>
          </td>
          <td>
            <Actions>
              <Tooltip title='Zobacz podgląd'>
                <IconWrapper onClick={() => {
                  navigate(`/${code}/podglad/${vip.id}`);
                }}>
                  <IconEye />
                </IconWrapper>
              </Tooltip>
            </Actions>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  ) : (
    <div>Brak aktywnych członków</div>
  )
}

export default VipsTable


const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;

  th {
    text-align: left;
    padding: 16px;
    background-color: #f2f2f2;
  }

  td {
    padding: 8px 16px;
    border-bottom: #ccc;
    border-bottom: 1px solid #f2f2f2;
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`

const IconWrapper = styled.div`
  cursor: pointer;
`

const PersonalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Placeholder = styled.div`
  width: 50px;
  height: 50px;
`
