import React, { ReactNode, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import AppContext, { AppContextTypes } from 'AppContext'
import { CircularProgress } from '@mui/material'
import { isNilOrEmpty } from 'utils/ramda'
import AdminMenu from 'admin/components/admin/AdminMenu'
import { supabase } from 'utils/supabase'

const AdminPageWrapper = ({ children }: { children: ReactNode }) => {
  const { user, saveUserData } = useContext(AppContext) as AppContextTypes
  const [loading, setLoading] = useState(isNilOrEmpty(user))

  useEffect(() => {
    const getUser = async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (user) {
        saveUserData(user)
        setLoading(false)
      } else {
        window.location.replace('/admin')
      }
    }

    getUser()
  }, [])

  return loading
    ? (
      <EmptyState>
        <CircularProgress size={60} />
      </EmptyState>
    )
    : (
      <Wrapper>
        <AdminMenu />
        <Content>
          {children}
        </Content>
      </Wrapper>
    )
}

export default AdminPageWrapper

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  height: calc(100% - 30px);
  overflow-y: auto;
`

const EmptyState = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
