export const Theme = {
  colors: {
    primary: '#c59b51',
    primaryLight: '#ecdec6',
    secondary: '#00265d',
    secondaryLight: '#99c3ff',
    white: '#FFF'
  },
  fontFamily: 'Ubuntu, sans-serif',
}

export const mobileBreakPoint = 1036

export const mobileCss = (css: string) => `
  @media (max-width: ${mobileBreakPoint}px) {
    ${css}
  }
`
