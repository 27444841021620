import React from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';

interface WysiwygEditorProps {
  name: string;
  value: string;
  label: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  onChange: (name: string, value: string) => void;
}

const WysiwygEditor = ({ name, value, onChange, label, error, helperText, required }: WysiwygEditorProps) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    ['link'],
    [{ 'size': [] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'color': ['#fff', '#bbb', '#c59b51', '#00265d', '#cc0000', '#32a84e'] }],
    [{ 'align': [] }]
  ];
  
  const handleValueChange = (value: string) => {
    onChange(name, value);
  };
  
  return (
    <div>
      <EditorLabel>{label}{required && ' *'}</EditorLabel>
      <EditorWrapper error={error}>
        <ReactQuill
          theme='snow'
          value={value}
          onChange={handleValueChange}
          modules={{ toolbar: toolbarOptions }}
        />
      </EditorWrapper>
      {error && <HelperText>{helperText}</HelperText>}
    </div>
  );
};

export default WysiwygEditor;

const EditorLabel = styled.div`
  margin-bottom: 8px;
`;

const EditorWrapper = styled.div<{ error?: boolean }>`
  border: 1px solid ${({ error }) => (error ? 'red' : '#ccc')};
  border-radius: 4px;
  padding: 5px;
  min-height: 200px;

  .ql-container {
    min-height: 150px; // Ensure the editor has a minimum height
  }
`;

const HelperText = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;
