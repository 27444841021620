import React from 'react'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'
import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'

const VipPreview = ({ vip }: { vip: VipTableRowTypes | null }) => {
  const navigate = useNavigate()
  
  const presentationLinks = Array.from({ length: 10 }, (_, i) => ({
    titleKey: `vipPresentationTitle${i + 1}`,
    linkKey: `vipPresentationLink${i + 1}`
  }))
  
  const renderHTMLContent = (content: string | undefined) => {
    return { __html: content || '' }
  }
  
  const handleGoBack = () => {
    navigate(-1)
  }
  
  const formatUrl = (url: string | undefined) => {
    if (!url) return ''
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`
    }
    return url
  }
  
  return (
    <VipWrapper>
      <PersonalWrapper>
        <FullName>{vip?.vipFullName}</FullName>
        <Picture>
          {vip?.vipImageUrl && (
            <img style={{ width: 250 }} src={vip.vipImageUrl} alt='vip image' />
          )}
        </Picture>
        <Title>{vip?.vipTitle}</Title>
      </PersonalWrapper>
      <Divider />
      <ShortDescription dangerouslySetInnerHTML={renderHTMLContent(vip?.vipShortDescription)} />
      <Divider />
      {vip?.vipDescriptionP1 && <ParagraphHeadline>O mnie</ParagraphHeadline>}
      {vip?.vipDescriptionP1 && <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP1)} />}
      {vip?.vipDescriptionP2 && <ParagraphHeadline>Moje firmy</ParagraphHeadline>}
      {vip?.vipDescriptionP2 && <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP2)} />}
      {vip?.vipDescriptionP3 && <ParagraphHeadline>Moja działalność społeczna</ParagraphHeadline>}
      {vip?.vipDescriptionP3 && <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP3)} />}
      {/*{vip?.vipDescriptionP4 && <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP4)} />}*/}
      {/*{vip?.vipDescriptionP5 && <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP5)} />}*/}
      {/*{vip?.vipDescriptionP6 && <Paragraph dangerouslySetInnerHTML={renderHTMLContent(vip?.vipDescriptionP6)} />}*/}
      <Divider />
      <PresentationsSection>
        <ParagraphHeadline>Moje prezentacje</ParagraphHeadline>
        {presentationLinks.map(({ titleKey, linkKey }) => (
          vip?.[titleKey as keyof VipTableRowTypes] && vip?.[linkKey as keyof VipTableRowTypes] && (
            <PresentationButton
              key={linkKey}
              target='_blank'
              rel='noopener noreferrer'
              // @ts-ignore
              href={formatUrl(vip[linkKey as keyof VipTableRowTypes])}
            >
              {vip[titleKey as keyof VipTableRowTypes]}
            </PresentationButton>
          )
        ))}
      </PresentationsSection>
      <Divider />
      <ContactWraper>
        <PersonalLink href={formatUrl(vip?.vipWebsite)} target='_blank'>{vip?.vipWebsite}</PersonalLink>
        <PersonalLink href={formatUrl(vip?.vipLinkedIn)} target='_blank'>{vip?.vipLinkedIn}</PersonalLink>
        <PersonalLink href={`mailto:${vip?.vipEmail}`}>{vip?.vipEmail}</PersonalLink>
        <Mobile>{vip?.vipMobile}</Mobile>
      </ContactWraper>
      <Divider />
      <Button onClick={handleGoBack}>Powrót do listy</Button>
    </VipWrapper>
  )
}

export default VipPreview

const VipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 800px;
  padding-top: 20px;
  margin-left: 40px;

  @media (max-width: 1036px) {
    width: 100%;
    margin-left: 0;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #b3b3b3;
  margin: 24px 0;
`
const PersonalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

const FullName = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mobileCss(`
    font-size: 26px;
  `)};
`
const Picture = styled.div`
`
const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
`
const ShortDescription = styled.div`
  text-align: center;
  font-weight: bold;
`

const Paragraph = styled.div`
  padding-top: 14px;
`
const PresentationsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  gap: 10px;
  max-width: 100%;
  overflow-x: auto;

  @media (max-width: 1036px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`
const PresentationButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  padding: 5px 10px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`
const ContactWraper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: center;
`
const PersonalLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`
const ParagraphHeadline = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 1036px) {
    padding-bottom: 5px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const Mobile = styled.div`

`
