import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '@mui/material'
import VipPreview from 'admin/components/VipPreview'
import VipPageWrapper from 'admin/components/VipPageWrapper'
import { VipTableRowTypes } from 'utils/vipTableRowTypes'
import AdminPageWrapper from 'admin/components/admin/AdminPageWrapper'

const AdminVipPreview = () => {
  const [vip, setVip] = useState<VipTableRowTypes | null>(null) // Remove the erroneous initialization
  const { id } = useParams()
  const navigate = useNavigate()

  const fetchVip = async () => {
    try {
      const response = await supabase.from('vip_links').select().eq('id', id)
      const data = response.data ? response.data[0] : null

      if (data) {
        setVip(data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchVip()
  }, [id])
  
  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <AdminPageWrapper>
      <Header>
        <Button variant='outlined' onClick={handleGoBack}>Powrót</Button>
      </Header>
      <VipPreview vip={vip} />
    </AdminPageWrapper>
  )
}

export default AdminVipPreview

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
 
`
