import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, TextField, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { supabase } from 'utils/supabase';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { omit } from 'ramda';
import { getFormInitialValues, vipFields } from 'utils/vips';
import ImageUploader from 'admin/components/ImageUploader';
import { VipTableRowTypes } from 'utils/vipTableRowTypes';
import WysiwygEditor from 'components/WysiwygEditor';
import { mobileCss } from 'utils/theme';

const fields = vipFields;

interface VipFormProps {
  vip?: VipTableRowTypes;
}
type PresentationKey = `vipPresentationTitle${number}` | `vipPresentationLink${number}`;

const requiredKeys = ['vipFullName', 'vipTitle', 'vipDescriptionP1', 'vipDescriptionP2', 'vipDescriptionP3'];

const VipForm = ({ vip }: VipFormProps) => {
  const [values, setValues] = useState<Partial<VipTableRowTypes>>(getFormInitialValues(fields));
  const [isFormValid, setIsFormValid] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const navigate = useNavigate();
  
  const handleValueChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValues(prev => ({ ...prev, [name]: e.target.value }));
  };
  
  const handleCustomValueChange = (name: string, value: string | null) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };
  
  useEffect(() => {
    vip && setValues(omit(['id'], vip));
  }, [vip]);
  
  useEffect(() => {
    validateForm();
  }, [values]);
  
  const validateForm = () => {
    const missing = requiredKeys.filter(key => {
      const value = values[key as keyof VipTableRowTypes];
      return typeof value !== 'string' || value.trim() === '';
    });
    setMissingFields(missing);
    setIsFormValid(missing.length === 0);
  };
  
  const handleUploadFile = (url: string) => {
    setValues(prev => ({ ...prev, vipImageUrl: url }));
  };
  
  const handleRemoveImage = () => {
    setValues(prev => ({ ...prev, vipImageUrl: null }));
  }
  
  const handleGoBack = () => {
    navigate(-1);
  }
  
  const fieldLabels = {
    vipFullName: 'Pełne imię',
    vipTitle: 'Stanowisko',
    vipDescriptionP1: 'Branża/branże',
    vipDescriptionP2: 'Bio',
    vipDescriptionP3: 'Moje firmy/Opis biznesu'
  }
  
  const missingFieldLabels = missingFields.map(field => fieldLabels[field as keyof typeof fieldLabels])
  
  const generateFields = () => {
    return (
      <>
        {fields.map((field) => {
          if (field.key === 'vipSortingName') {
            return null;
          }
          
          if (field.type === 'input') {
            return (
              <TextField
                id={field.label}
                size='small'
                required={requiredKeys.includes(field.key)}
                fullWidth
                multiline
                // @ts-ignore
                value={values[field.key]}
                onChange={handleValueChange(field.key)}
                label={field.label}
                variant='outlined'
                error={missingFields.includes(field.key)}
                helperText={missingFields.includes(field.key) ? 'To pole jest wymagane' : ''}
              />
            );
          }
          
          if (field.type === 'wysiwyg') {
            return (
              <WysiwygEditor
                name={field.key}
                label={field.label}
                onChange={handleCustomValueChange}
                required={requiredKeys.includes(field.key)}
                // @ts-ignore
                value={values[field.key]}
                error={missingFields.includes(field.key)}
                helperText={missingFields.includes(field.key) ? 'To pole jest wymagane' : ''}
              />
            );
          }
          
          return <div>{field.type}</div>;
        })}
      </>
    );
  };
  
  const handleSubmit = async () => {
    try {
      await supabase.from('vip_links').update([values]).eq('id', vip?.id || '');
      toast.success('Pomyślnie zapisano zmiany');
    } catch (err) {
      toast.error('Wystąpił błąd podczas zapisu');
      console.error(err);
    }
  };
  
  return (
    <>
      <Header>
        <Title>Edycja danych</Title>
        <Buttons>
          <Button onClick={handleGoBack}>Powrót</Button>
          <Tooltip
            title={missingFieldLabels.length > 0 ? `Brakujące pola: ${missingFieldLabels.join(', ')}` : ''}
            placement="top"
            arrow
          >
            <span>
              <Button onClick={handleSubmit} disabled={!isFormValid}>Zapisz</Button>
            </span>
          </Tooltip>
        </Buttons>
      </Header>
      <FormWrapper>
        {values.vipImageUrl ? (
          <>
            <img style={{ width: '200px', height: 'auto' }} src={values.vipImageUrl} alt='vip img' />
          </>
        ) : <Placeholder />}
        <ImageSection>
          <ImageUploader onUpload={handleUploadFile} />
          <Button onClick={handleRemoveImage}>Usuń zdjęcie</Button>
        </ImageSection>
        {generateFields()}
      </FormWrapper>
    </>
  );
};

export default VipForm;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ql-editor {
    min-height: 100px !important;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  ${mobileCss(`
    flex-direction: column;
  `)};
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Title = styled.div`
  font-size: 24px;
`

const ImageSection = styled.div`
  display: flex;
  gap: 10px;

  ${mobileCss(`
    flex-direction: column;
  `)};
`

const Placeholder = styled.div`
  width: 200px;
  height: 200px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
`;
